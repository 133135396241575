import React, { useContext, useEffect, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Context from "../../store/context";
import { fetchJsonFromApiJsonResponse } from "./http";
import { UserDetails, UserState } from "store/interfaces";
import { isWIOCCEmployee } from "Utils/Util";

const ProtectedRoute = () => {
  const { globalState, globalDispatch } = useContext(Context);
  const loginRef = useRef<{
    isLoginError: boolean;
    isLoggedIn: boolean;
    loginAttempts: number;
  }>({ isLoginError: false, isLoggedIn: false, loginAttempts: 0 });

  useEffect(() => {
    if (
      !globalState ||
      !globalState.name ||
      globalState.name.trim().length === 0
    ) {
      if (!loginRef.current.isLoginError && !loginRef.current.isLoggedIn) {
        fetchJsonFromApiJsonResponse("getUserDetails")
          .then((response: UserDetails) => {
            if (response) {
              if (response.user) {
                const user: UserState = response.user;
                user.prtg_graphs_api = response.prtg_graphs_api;
                const {
                  supplier_group_id,
                  supplier_id,
                  client_group_id,
                  client_id,
                  email,
                  name,
                } = user;

                if (
                  !supplier_group_id &&
                  !supplier_id &&
                  !client_group_id &&
                  !client_id &&
                  !isWIOCCEmployee(email ?? "")
                )
                  throw new Error("Invalid user details.");

                if (name && name.trim().length > 0) {
                  loginRef.current.isLoggedIn = true;
                  globalDispatch({ type: "LOGIN", payload: user });
                }
              } else
                throw new Error(
                  "Login exception. User details were not found."
                );
            } else
              throw new Error("Login exception. Could not fetch user details.");
          })
          .catch((error) => {
            loginRef.current.isLoginError = true;
            loginRef.current.isLoggedIn = false;
            loginRef.current.loginAttempts = loginRef.current.loginAttempts++;
            console.log(`Exception ${error}`);
          });
      } else
        console.log(
          "Initial login attempts encountered an error(s) or you are already logged in; cannot proceed."
        );
    }
  });

  return globalState.isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};
export default ProtectedRoute;
